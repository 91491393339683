import Description from "@components/CustomTypography/Description/Description"
import Iframe from "@components/CustomTypography/Iframe/Iframe"
import NewResponsiveImage from "@components/CustomTypography/NewResponsiveImage/NewResponsiveImage"
import Paragraph from "@components/CustomTypography/Paragraph/Paragraph"
import Separator, {
	SeparatorType,
} from "@components/CustomTypography/Separator/Separator"
import Title from "@components/CustomTypography/Title/Title"
import ButtonComponent from "@components/Theme/Button/Button"
import {Translate} from "@components/Theme/Translate/Translate"
import {direction} from "@customTypes/customTypes"
import {
	BlogPostModuleType,
	CategoryModuleType,
} from "@hooks/WorkWithJson/GetComponentDataByKey/GetComponentDataByKeyTypes"
import Link from "next/link"
import React from "react"

import CardsCategoryButton from "../CardsCategoryButton/CardsCategoryButton"

import styles from "./BlogVerticalCard.module.scss"

type Props = {
	data: BlogPostModuleType
	container?: SeparatorType
	minimalPost?: boolean
	direction: direction
}

const BlogVerticalCard = (props: Props): JSX.Element => {
	return (
		<Link
			className={`${styles.cardContainer} ${styles[props.direction]}`}
			href={props.data.link}
			rel={"noreferrer"}
		>
			{!props.minimalPost && (
				<div className={styles.assetContainer}>
					{props.data.thumbui.type === "image" && (
						<NewResponsiveImage
							{...props.data.thumbui}
							containerClassName={styles.imageContainer}
							imageClassName={styles.image}
						/>
					)}
					{props.data.thumbui.type === "video" && (
						<Iframe {...props.data.thumbui} />
					)}
				</div>
			)}

			<div
				className={
					!props.minimalPost
						? styles.contentContainer
						: styles.contentContainerMinimal
				}
			>
				<Title
					capitalize={false}
					className={styles.readMore}
					content={props.data.title}
					fontWeight={600}
					size={"S"}
				/>
				<Paragraph
					content={
						<>
							Posted on: {props.data.upperTitle}
							{props.data.category.map(
								(
									element: CategoryModuleType,
									index: number,
								) => {
									return (
										<React.Fragment key={index}>
											{" "}
											|{" "}
											<CardsCategoryButton
												data={element}
												link={"newsArchive"}
												modifier={"category"}
											/>
										</React.Fragment>
									)
								},
							)}
						</>
					}
					fontWeight={400}
					size={"XS"}
				/>
				<Separator size={16} />
				<Description
					content={props.data.content}
					shortDesc={400}
					size={"M"}
				/>

				<object>
					<ButtonComponent buttonType={"readMore"} asLink>
						<Translate> Read More </Translate>
					</ButtonComponent>
				</object>
			</div>
			{props.minimalPost && (
				<div className={styles.minimalAsset}>
					<Paragraph
						content={props.data.upperTitle}
						fontWeight={600}
						size={"M"}
					/>
				</div>
			)}
		</Link>
	)
}

export default BlogVerticalCard
